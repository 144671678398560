import Settings from "../../../Settings.json";

import { useEffect, useState } from "react";
import axios from "axios";

import Loading from "../../loading";

function CarModelContainer({ selectCar, handleSelect, carInfo, setCarInfo }) {
  const [models, setModels] = useState([]);

  const [search, setSearch] = useState("");

  useEffect(() => {
    if (selectCar && selectCar.carName && selectCar.modelId === null) {
      setModels([]);
      setSearch("");

      axios
        .get(`${Settings.API_URL}/?get=bases&make=${selectCar.carName}`)

        .then(function (response) {
          const results = response.data.results;

          const resultsUpdate = Object.keys(results)
            .map((keyName, i) => [
              { title: keyName, data: results[keyName], hide: true, id: i },
            ])
            .map((d) => d[0]);

          setModels(resultsUpdate);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [selectCar]);

  const filteredData =
    selectCar.carName &&
    models.length > 0 &&
    models.filter((el) => {
      //if no input the return the original
      if (search === "") {
        return el;
      }
      //return the item which contains the user input
      else {
        return el.title.toLowerCase().includes(search.toLocaleLowerCase());
      }
    });

  return selectCar.carName && models.length > 0 ? (
    <div className="carSelectContainer-car-model">
      <div className="input-container">
        <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 50 50">
          <path d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z " />
        </svg>

        <input
          placeholder="Arama Yap"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <section>
        {filteredData.map((d) => (
          <section key={d.length}>
            <label
              onClick={() => {
                setModels(
                  models
                    .map((da) =>
                      da.hide === false ? { ...da, hide: true } : da
                    )
                    .map((da) =>
                      da.id === d.id ? { ...da, hide: !da.hide } : da
                    )
                );
              }}
            >
              {d.title}
            </label>

            {d.data.map((f) => (
              <div
                style={{
                  display: d.hide ? "none" : "block",
                }}
                key={f.objectId}
                onClick={() => {
                  handleSelect({ type: "model", id: f.objectId });
                  setCarInfo({
                    ...carInfo,
                    type: null,
                    model:
                      f.startYear +
                      " - " +
                      (f.endYear !== null ? f.endYear : ""),
                  });
                }}
              >
                {f.startYear + " - " + (f.endYear !== null ? f.endYear : "")}
              </div>
            ))}
          </section>
        ))}
      </section>
    </div>
  ) : (
    <Loading isLoading={selectCar.carName && !models.length > 0} />
  );
}

export default CarModelContainer;
