import Settings from "./Settings.json";
import wpLogo from "./assets/wp.png";

import Header from "./components/Header";
import Footer from "./components/footer";
import DetailBanner from "./components/detailBanner";
import HomeBanner from "./components/homeBanner";
import VehicleBrandsBanner from "./components/vehicleBrandsBanner";
import SupportedVehicles from "./components/SupportedVehicles";
import FeatureOptionsBanner from "./components/featureOptionsBanner";

import { useEffect } from "react";

function App() {
  useEffect(() => {
    window.onscroll = function () {
      const header = document.getElementById("header");
      const whatsapp = document.getElementById("whatsapp");

      if (window.pageYOffset > 200) {
        header.classList.add("header-sticky");
      } else {
        header.classList.remove("header-sticky");
      }

      const scrollLastNumber =
        window.scrollMaxY ||
        document.documentElement.scrollHeight -
          document.documentElement.clientHeight;

      if (window.pageYOffset > scrollLastNumber - 200) {
        whatsapp.classList.add("whatsapp-remove");
      } else {
        whatsapp.classList.remove("whatsapp-remove");
      }
    };
  }, []);

  return (
    <>
      <Header />

      <main className="main">
        <HomeBanner />

        <VehicleBrandsBanner />

        <DetailBanner />

        <SupportedVehicles />

        <FeatureOptionsBanner />

        <div className="whatsapp" id="whatsapp">
          <a href={Settings.wpUrl} target="_blank" rel="noreferrer">
            <img src={wpLogo} alt="wp-logo" />
          </a>
        </div>
      </main>

      <Footer />
    </>
  );
}

export default App;
