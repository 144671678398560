import loadingImage from "../../assets/loading.gif";

function loading({ isLoading = false }) {
  return (
    isLoading && (
      <div className="loading-container">
        <img src={loadingImage} alt="loading" />
      </div>
    )
  );
}

export default loading;
