import Settings from "../../../Settings.json";

import CarsContainer from "./CarsContainer";
import CarModelContainer from "./CarModelContainer";
import CarBodyContainer from "./CarBodyContainer";
import CarTypesContainer from "./CarTypesContainer";

import { useState, useEffect } from "react";
import axios from "axios";

function CarSelect({ setCarDetail, carInfo, setCarInfo }) {
  const [selectCar, setSelectCar] = useState({
    carName: "",
    modelId: null,
    bodyId: null,
    typeId: null,
  });

  const handleSelect = (e) => {
    switch (e.type) {
      case "car":
        setSelectCar({
          carName: e.id,
          modelId: null,
          bodyId: null,
          typeId: null,
        });
        break;

      case "model":
        setSelectCar({
          ...selectCar,
          modelId: e.id,
          bodyId: null,
          typeId: null,
        });
        break;

      case "body":
        setSelectCar({ ...selectCar, bodyId: e.id, typeId: null });
        break;

      case "type":
        setSelectCar({ ...selectCar, typeId: e.id });
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    const DIAGNOSTICS = [
      {
        id: 99,
        hide: true,
        title: "DIAGNOSTICS",
        data: [
          {
            name: "Canlı Veri",
            description:
              "Aracınızın çalışma değerleri gerçek zamanlı olarak canlı verilere erişim sağlanarak gözlemlenir. Tüm kontrol üniteleri grafikler ve göstergeler ile temsil edilir.",
            picture:
              "https://obdeleven.com/modules/supportedvehicles/views/img/chart.jpg",
          },
          {
            name: "Arıza Tespiti",
            description:
              "Aracınız taranarak tüm kontrol ünitelerine tam olarak erişilir ve kontrol ünitelerinden alınan detaylı bilgiler sayesinde (motor, şanzıman, ABS, hava yastığı, multimedya, klima vb.) aktif arızanın düzeltilmesi için tarafınıza gerekli bilgilendirmeler yapılır. Aracın geçmişte verdiği ancak şuanda mevcut olmayan pasif arızalar ise silinir.",
            picture:
              "https://obdeleven.com/modules/supportedvehicles/views/img/diagnostics.jpg",
          },
          {
            name: "Araç Geçmişi",
            description:
              "Aracınızla ilgili ne zaman ve ne tür değişiklikler yapıldığı hakkında bilgi alın.",
            picture:
              "https://obdeleven.com/modules/supportedvehicles/views/img/history.jpg",
          },
        ],
      },
    ];

    const CODING = [
      {
        id: 999,
        hide: true,
        title: "CODING",
        data: [
          {
            name: "Programlama",
            description:
              "Kontrol modüllerindeki belirli değerler veya ayarlar değiştirilir. (Örn. Xenon far yıkama aralığının değiştirilmesi.)",
            picture:
              "https://obdeleven.com/modules/supportedvehicles/views/img/programming.jpg",
          },
          {
            name: "Kodlama",
            description:
              "Kontrol modüllerindeki çeşitli seçenekler değiştirilir. (Örn. Xenon far yıkamayı etkinleştirme/devre dışı bırakma.) Kodlama, başta yerel yasalar nedeniyle olmak üzere, fabrika tarafından araçta bulunmayan işlevlerin sonradan kullanımına izin vermek için bir araçta yapılan yazılım değişiklikleri anlamına gelir.",
            picture:
              "https://obdeleven.com/modules/supportedvehicles/views/img/coding.jpg",
          },

          {
            name: "Basit Ayarlar",
            description:
              "Direksiyon açısı sensörü, klima flap motorları, şanzıman vb. gibi adaptasyon isteyen mekanik parçaların kalibrasyonu yapılır. (Yakında)",
            picture:
              "https://obdeleven.com/modules/supportedvehicles/views/img/settings.jpg",
          },
        ],
      },
    ];

    if (selectCar && selectCar.typeId !== null) {
      setCarDetail([]);

      axios
        .get(`${Settings.API_URL}/?get=apps&base_id=${selectCar.modelId}`)
        .then(function (response) {
          const results = response.data.results;

          const resultsUpdate = Object.keys(results)
            .map((keyName, i) => [
              { title: keyName, data: results[keyName], hide: true, id: i },
            ])
            .map((d) => d[0]);

          const ADJUSTMENT = resultsUpdate.filter(
            (d) => d.title === "ADJUSTMENT"
          )
            ? resultsUpdate.filter((d) => d.title === "ADJUSTMENT")[0]
              ? resultsUpdate.filter((d) => d.title === "ADJUSTMENT")[0].data
              : []
            : [];

          const RETROFIT = resultsUpdate.filter((d) => d.title === "RETROFIT")
            ? resultsUpdate.filter((d) => d.title === "RETROFIT")[0]
              ? resultsUpdate.filter((d) => d.title === "RETROFIT")[0].data
              : []
            : [];

          const ADREJ =
            ADJUSTMENT.length > 0 || RETROFIT.length > 0
              ? [
                  {
                    id: 0,
                    title: "ADJUSTMENT",
                    hide: true,
                    data: [...ADJUSTMENT, ...RETROFIT],
                  },
                ]
              : [];

          const resultsUpdateFinal = [
            ...DIAGNOSTICS,
            ...ADREJ,
            ...resultsUpdate
              .filter((d) => d.title !== "ADJUSTMENT")
              .filter((d) => d.title !== "RETROFIT"),
            ...CODING,
          ];

          setCarDetail(resultsUpdateFinal);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setCarDetail([]);
    }
  }, [selectCar]);

  return (
    <>
      <section className="SupportedVehicles-carSelectContainer">
        <CarsContainer
          selectCar={selectCar}
          handleSelect={handleSelect}
          carInfo={carInfo}
          setCarInfo={setCarInfo}
        />

        <CarModelContainer
          selectCar={selectCar}
          handleSelect={handleSelect}
          carInfo={carInfo}
          setCarInfo={setCarInfo}
        />

        <CarBodyContainer
          selectCar={selectCar}
          handleSelect={handleSelect}
          carInfo={carInfo}
          setCarInfo={setCarInfo}
        />

        <CarTypesContainer
          selectCar={selectCar}
          handleSelect={handleSelect}
          carInfo={carInfo}
          setCarInfo={setCarInfo}
        />
      </section>
    </>
  );
}

export default CarSelect;
