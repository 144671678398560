import Settings from "../../Settings.json";

import aracLogo from "../../assets/car.png";

function featureOptionsBanner() {
  return (
    <div className="featureOptionsBanner" id="fav">
      <h3>{Settings.optionsBannerTitle}</h3>
      <p>{Settings.optionsBannerSubTitle}</p>

      <div className="featureOptionsBanner-wrapper">
        <div className="featureOptionsBanner-wrap featureOptionsBanner-wrap-left">
          {Settings.optionsBannerLeftData.map((d) => (
            <label key={d.id}>
              <div>
                <span>{d.title}</span>
                <p>{d.content}</p>
              </div>

              <img
                src={require(`../../assets/icons2/${d.img}`)}
                alt={d.title}
              />
            </label>
          ))}
        </div>
        <div className="featureOptionsBanner-wrap">
          <img src={aracLogo} alt="audi-banner" />
        </div>
        <div className="featureOptionsBanner-wrap featureOptionsBanner-wrap-right">
          {Settings.optionsBannerRightData.map((d) => (
            <label key={d.id}>
              <div>
                <span>{d.title}</span>
                <p>{d.content}</p>
              </div>

              <img
                src={require(`../../assets/icons2/${d.img}`)}
                alt={d.title}
              />
            </label>
          ))}
        </div>
      </div>
    </div>
  );
}

export default featureOptionsBanner;
