import { useState } from "react";

import Modal from "../../modal";
import Loading from "../../loading";

function CarDetail({ carInfo, carDetail, setCarDetail }) {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [modalDetail, setModalDetail] = useState({
    id: null,
    title: null,
    picture: null,
    desc: null,
    modalData: null,
    index: null,
  });

  const nameToNameAndDesc = (name, desc = false) => {
    switch (name) {
      case "WORKSHOP":
        return desc
          ? "Önceden yapılandırılmış uygulamalar, servis ve muayene ışıklarının sıfırlaması veya servis parametrelerinin değişiklikleri yapılır."
          : "Servis";

      case "ADJUSTMENT":
        return desc
          ? "Aracınızın desteklediği gizli özellikler aktifleştirilir veya ek parça takılması durumunda yapılması gereken kodlamalar uygulanır."
          : "Uygulamalar";

      case "CODING":
        return desc
          ? "Volkswagen (VAG) resmi lisanslı ekipmanlar sayesinde tüm VAG modellerinin özelleştirilmesi için SFD kilidi açılarak kodlama yapılır."
          : "Kodlama / Programlama";

      case "DIAGNOSTICS":
        return desc
          ? "Evrimsel bulut tabanlı otomobil teşhis uygulaması sayesinde tüm kontrol ünitelerine erişilerek kapsamlı araç sistemi teşhisi yapılır."
          : "Teşhis";

      default:
        break;
    }
  };

  const modalHandlePrevAndNext = (type) => {
    if (type === "next") {
      if (modalDetail.modalData.data[modalDetail.index + 1]) {
        setModalDetail({
          ...modalDetail,
          title: modalDetail.modalData.data[modalDetail.index + 1].name,
          desc: modalDetail.modalData.data[modalDetail.index + 1].description,
          picture: modalDetail.modalData.data[modalDetail.index + 1].picture,
          index: modalDetail.index + 1,
        });
      }
    } else {
      if (modalDetail.modalData.data[modalDetail.index - 1]) {
        setModalDetail({
          ...modalDetail,
          title: modalDetail.modalData.data[modalDetail.index - 1].name,
          desc: modalDetail.modalData.data[modalDetail.index - 1].description,
          picture: modalDetail.modalData.data[modalDetail.index - 1].picture,
          index: modalDetail.index - 1,
        });
      }
    }
  };

  return carInfo.type !== null && carDetail.length === 0 ? (
    <section className="SupportedVehicles-detail">
      <div className="SupportedVehicles-detail-container">
        <Loading isLoading={true} />
      </div>
    </section>
  ) : (
    carDetail && carDetail.length > 0 && (
      <>
        <Modal
          active={isOpenModal}
          handleClose={() => {
            setIsOpenModal(false);
            setModalDetail({
              id: null,
              title: null,
              picture: null,
              desc: null,
              modalData: null,
              index: null,
            });
          }}
        >
          <div className="car-detail-modal">
            <img src={modalDetail.picture} alt="modal-serv" />

            <div className="car-detail-modal-content">
              <span>
                {carInfo.brand + " " + carInfo.type + " " + carInfo.model}
              </span>
              <h3>{modalDetail.title}</h3>
              <p dangerouslySetInnerHTML={{ __html: modalDetail.desc }}></p>

              {!(
                modalDetail.modalData &&
                modalDetail.modalData.data &&
                modalDetail.index === 0
              ) && (
                <div className="car-detail-modal-button-container car-detail-modal-button-container-lef">
                  <div onClick={() => modalHandlePrevAndNext("prev")}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#fff"
                      viewBox="0 0 8 8"
                    >
                      <path d="M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z" />
                    </svg>
                  </div>
                </div>
              )}

              {!(
                modalDetail.modalData &&
                modalDetail.modalData.data &&
                modalDetail.index === modalDetail.modalData.data.length - 1
              ) && (
                <div className="car-detail-modal-button-container">
                  <div onClick={() => modalHandlePrevAndNext("next")}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#fff"
                      viewBox="0 0 8 8"
                    >
                      <path d="M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z" />
                    </svg>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal>

        <section className="SupportedVehicles-detail">
          {/* <h3>
              PREVIOUSLY SELECTED VEHICLE (
              {carInfo.brand + " " + carInfo.type + " " + carInfo.model})
            </h3> */}

          <div className="SupportedVehicles-detail-container">
            {carDetail
              .filter((fi) => fi.data.length > 0)
              .map((d) => (
                <div key={d.id}>
                  <div className="SupportedVehicles-detail-container-bar">
                    <div
                      className="SupportedVehicles-detail-container-bar-title"
                      onClick={() => {
                        setCarDetail(
                          carDetail.map((f) =>
                            f.id === d.id ? { ...f, hide: !f.hide } : f
                          )
                        );
                      }}
                    >
                      <span>{nameToNameAndDesc(d.title)}</span>
                      {d.hide ? "+" : "-"}
                    </div>
                  </div>

                  <div
                    className={
                      !d.hide
                        ? `SupportedVehicles-detail-container-bar-content SupportedVehicles-detail-container-bar-content-active`
                        : `SupportedVehicles-detail-container-bar-content`
                    }
                  >
                    <p>{nameToNameAndDesc(d.title, true)}</p>

                    <div className="SupportedVehicles-detail-container-bar-content-wrapper">
                      {/* eslint-disable jsx-a11y/anchor-is-valid */}
                      {d.data.map((f) => (
                        <div
                          key={f.id}
                          onClick={() => {
                            setIsOpenModal(true);

                            setModalDetail({
                              id: d.title,
                              title: f.name,
                              picture: f.picture,
                              desc: f.description,
                              modalData: d,
                              index: d.data.findIndex((d) => d.name === f.name),
                            });
                          }}
                          className="SupportedVehicles-detail-container-bar-content-wrapper-wrap"
                          style={{ backgroundImage: `url(${f.picture})` }}
                        >
                          <div className="SupportedVehicles-detail-container-bar-content-wrapper-wrap-content">
                            {f.name}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </section>
      </>
    )
  );
}

export default CarDetail;
