import Settings from "../../../Settings.json";

import { useEffect, useState } from "react";
import axios from "axios";

import Loading from "../../loading";

function CarsContainer({ selectCar, handleSelect, carInfo, setCarInfo }) {
  const [cars, setCars] = useState([]);

  useEffect(() => {
    axios
      .get(Settings.API_URL, "get=makes")
      .then(function (response) {
        setCars(response.data.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [selectCar]);

  return (
    <div className="carSelectContainer-cars">
      <Loading isLoading={!cars.length > 0} />

      {cars.length > 0 &&
        cars.map((d) => (
          <label
            onClick={() => {
              handleSelect({ type: "car", id: d });
              setCarInfo({ brand: d, model: null, type: null });
            }}
            key={d}
            className={
              d === selectCar.carName ? "carSelectContainer-cars-active" : ""
            }
          >
            {d}
          </label>
        ))}
    </div>
  );
}

export default CarsContainer;
