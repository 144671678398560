import Settings from "../../Settings.json";

import supportedVehiclesBG from "../../assets/5529884.jpeg";

import CarSelect from "./CarSelect";
import CarDetail from "./CarDetail";

import { useState } from "react";

function SupportedVehicles() {
  const [carInfo, setCarInfo] = useState({
    brand: null,
    model: null,
    type: null,
  });

  const [carDetail, setCarDetail] = useState([]);

  return (
    <section id="supported-vehicles">
      <section
        className="SupportedVehiclesContainer"
        style={{ backgroundImage: `url(${supportedVehiclesBG})` }}
      >
        <section className="SupportedVehicles">
          <h2>
            <span>{Settings.SupportedVehiclesTitle}</span>{" "}
            {Settings.SupportedVehiclesTitleTwo}
          </h2>
          <h3>{Settings.SupportedVehiclesSubTitle}</h3>

          <CarSelect
            carInfo={carInfo}
            setCarInfo={setCarInfo}
            setCarDetail={setCarDetail}
          />
        </section>
      </section>

      <CarDetail
        carInfo={carInfo}
        carDetail={carDetail}
        setCarDetail={setCarDetail}
      />
    </section>
  );
}

export default SupportedVehicles;
