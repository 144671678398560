import Settings from "../../Settings.json";

import vwLogo from "../../assets/brands-white/VW.png";
import seatLogo from "../../assets/brands-white/Seat.png";
import audiLogo from "../../assets/brands-white/Audi.png";
import skodaLogo from "../../assets/brands-white/Skoda.png";
import lamborghiniLogo from "../../assets/brands-white/Lamborghini.png";
import bentleyLogo from "../../assets/brands-white/Bentley.png";
import logo from "../../assets/Logo-White.png";

function footer() {
  return (
    <footer className="footer" id="footer">
      <iframe
        src={Settings.googleMapsUrl}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="maps"
      ></iframe>

      <div>
        <section className="footer-brands">
          <img src={audiLogo} alt="audiLogo" />

          <img src={vwLogo} alt="vwLogo" />

          <img src={skodaLogo} alt="skodaLogo" />

          <img src={seatLogo} alt="seatLogo" />

          <img src={lamborghiniLogo} alt="lamborghiniLogo" />

          <img src={bentleyLogo} alt="bentleyLogo" />
        </section>

        <div>
          Copyright (C) <b>VAGKOD </b> 2022
        </div>

        <img src={logo} alt="vagkod" />
      </div>

      <span id="siteseal">
        <script
          async
          type="text/javascript"
          src="https://seal.godaddy.com/getSeal?sealID=72Jxvfw7Cd2kkiQi20V9OfrIL37RytFMqttVGkKtn8hGcFQjgVzXCZbwkKIn"
        ></script>
      </span>
    </footer>
  );
}

export default footer;
