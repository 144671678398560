import Settings from "../../Settings.json";
import logo from "../../assets/Logo-Black.png";

import { useState } from "react";

function Header() {
  const [menuIsOpen, setMenuIsOpen] = useState(
    window.innerWidth > 992 ? true : false
  );

  return (
    <header
      className="header"
      id="header"
      style={
        window.innerWidth > 992
          ? { display: "flex" }
          : {
              background: menuIsOpen && "none",
            }
      }
    >
      <a href="/">
        <div>
          <img src={logo} alt="vagkod" />
          <h1>{Settings.title}</h1>
        </div>
      </a>

      {menuIsOpen ? (
        <svg
          onClick={() => setMenuIsOpen(!menuIsOpen)}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24px"
          height="24px"
        >
          <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z" />
        </svg>
      ) : (
        <svg
          onClick={() => setMenuIsOpen(!menuIsOpen)}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24px"
          height="24px"
        >
          <path d="M 2 5 L 2 7 L 22 7 L 22 5 L 2 5 z M 2 11 L 2 13 L 22 13 L 22 11 L 2 11 z M 2 17 L 2 19 L 22 19 L 22 17 L 2 17 z" />
        </svg>
      )}

      <nav
        style={
          window.innerWidth > 992
            ? { display: "flex" }
            : { display: menuIsOpen ? "flex" : "none" }
        }
      >
        <span
          onClick={() => {
            setMenuIsOpen(window.innerWidth > 992 ? true : false);
            document.getElementById("home").scrollIntoView();
          }}
        >
          ANASAYFA
        </span>

        <span
          onClick={() => {
            setMenuIsOpen(window.innerWidth > 992 ? true : false);
            document.getElementById("detail-banner").scrollIntoView();
          }}
        >
          GENEL BİLGİLER
        </span>

        <span
          onClick={() => {
            setMenuIsOpen(window.innerWidth > 992 ? true : false);
            document.getElementById("supported-vehicles").scrollIntoView();
          }}
        >
          DESTEKLENEN ARAÇLAR
        </span>

        <span
          onClick={() => {
            setMenuIsOpen(window.innerWidth > 992 ? true : false);
            document.getElementById("fav").scrollIntoView(true);
          }}
        >
          FAVORİ ÖZELLİKLER
        </span>

        <span
          onClick={() => {
            setMenuIsOpen(window.innerWidth > 992 ? true : false);
            document.getElementById("footer").scrollIntoView();
          }}
        >
          İLETİŞİM
        </span>
      </nav>
    </header>
  );
}

export default Header;
