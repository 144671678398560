import Settings from "../../../Settings.json";

import { useEffect, useState } from "react";
import axios from "axios";

import Loading from "../../loading";

function CarBodyContainer({ selectCar, handleSelect, carInfo, setCarInfo }) {
  const [carBodys, setCarBodys] = useState([]);

  useEffect(() => {
    if (selectCar && selectCar.modelId && selectCar.bodyId === null) {
      setCarBodys([]);

      axios
        .get(`${Settings.API_URL}/?get=body_types&base_id=${selectCar.modelId}`)
        .then(function (response) {
          setCarBodys(response.data.results);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [selectCar]);

  const idToBody = (id) => {
    switch (id) {
      case null:
        return {
          id: null,
          image: null,
          name: "unkown",
        };

      case 1:
        return {
          id: 1,
          image:
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.03 70.34"><defs><style>.cls-hatchback-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;fill-rule:evenodd;}</style></defs><title>Hatchback</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_1-2-2" data-name="Layer 1-2"><path class="cls-hatchback-1" d="M35.78,37.56A15.89,15.89,0,1,1,19.89,53.45h0A15.89,15.89,0,0,1,35.76,37.56Zm126.12,0A15.89,15.89,0,1,1,146,53.47v0A15.9,15.9,0,0,1,161.9,37.56ZM82.38,26.17a15.71,15.71,0,0,0,.75-3.59c.16-2.75-5.88-3-9-2.76,6.06-4.5,16.06-9.3,24.11-12.09,6.64-2.22,15.57-2.27,22.69-2.42,1.23,0,1.19.5,1,1.53-.49,3.27-1.7,10.33-2.44,15.07-.35,2.29-.89,2.83-3.23,2.85C107,24.84,82.27,26.61,82.38,26.17Zm42.88-3.66c1.27-10.42,1.43-13.44,2.41-16a2.44,2.44,0,0,1,1.77-1.23c7.5.35,11,.23,18.23,1.91,2.58.72,7.86,6.73,9.19,8.54.51.7,4.3,5.1,3.69,6a2.19,2.19,0,0,1-2,1.07c-11.28.38-19,1.57-30.31,1.71C125.1,24.59,124.91,25.36,125.26,22.51ZM186.54,60.3c2.25-.39,4-.89,4.77-1.57,2.33-2.17,1.63-8.93,1.59-12.4,0-.84-2.15-.85-2.2-1.73-.31-5.34-2.61-15.16-3.19-20.65A8.7,8.7,0,0,0,183,17.14C178.84,14.6,167,7.66,162.58,5.78c-3-1.24-2.24-1.53-5.41-2-15.75-2.26-45.33-4.93-60.76,0-12.69,4-23.75,9.88-35.21,17-20.28,3.5-36.82,6.47-46.67,9.11-4.75,1.28-6.78,3.54-8.25,6.38-1.6,3.1-2.36,4.91-3.09,6.67C1.44,43.07,1,44.69,1,46.88c0,3.43-.27,5.88.69,9.28a5.72,5.72,0,0,0,5.62,4.05l4.94.16c2.39-.46,3.13-.33,2.93-3.53-.08-1.1-.09-2.23-.09-3.39a20.66,20.66,0,0,1,41.31-.91c0,.31,0,.61,0,.91,0,1.59,0,3.15-.11,4.64-.35,5.08-1.94,4.55,3,4.81l78.13-.09c3.55-.44,4.5,0,4-4.83q-.19-2.25-.18-4.53a20.66,20.66,0,0,1,41.31-.91c0,.31,0,.61,0,.91v3C182.39,60.37,181.52,61.16,186.54,60.3Z"/></g></g></g></svg>',
          name: "Hatchback",
        };

      case 2:
        return {
          id: 2,
          image:
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.05 64.8"><defs><style>.cls-coupe-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;fill-rule:evenodd;}</style></defs><title>Coupe</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_1-2-2" data-name="Layer 1-2"><path class="cls-coupe-1" d="M32.44,34.84A14.48,14.48,0,1,1,18,49.32,14.48,14.48,0,0,1,32.44,34.84Zm123.57,0a14.48,14.48,0,1,1-14.48,14.48h0A14.48,14.48,0,0,1,156,34.84h0ZM74.9,24.46c-.38,0,.59-1.79.68-3.27.15-2.51-4.44-2.7-7.29-2.52a85.76,85.76,0,0,1,21.06-11A68.34,68.34,0,0,1,103.78,5.6L114,5.27c1.12,0,1.08.46.94,1.39-.5,3.39-1.46,9.51-2.22,13.74-.38,2.08-.82,2.51-3,2.6ZM118,20.56c1-8.28,1.47-11.36,1.86-13.67.18-1,.37-1.55,1.61-1.58l2.86-.06c2.07,0,12,6.57,16,10.7,2.3,2.4,3.24,5.76-1.85,6l-17.87.83C117.78,22.91,117.69,23.15,118,20.56Zm60.86,35c2.69-.22,5.59-.44,7.94-.79a9.35,9.35,0,0,0,4.35-1.43c2.12-2,1.88-8.14,1.84-11.3,0-.77-2-.77-2-1.58-.28-4.87-.63-9.07-1.16-14.07-.22-2.07-1.94-3.76-4.65-4.61C177,19.21,164.85,17.57,161,16.43a19.34,19.34,0,0,1-5.61-2.73A162.19,162.19,0,0,0,139,4.75c-3.6-1.6-6.44-3.66-11.34-3.66-13,0-27.88-.89-40,3C76.12,7.71,65.57,14.36,54.6,19.54A315.57,315.57,0,0,0,13.13,27c-4.35,1.08-6.24,3.09-7.58,5.68a66.23,66.23,0,0,0-2.81,7.07c-1.07.09-1.11.41-1.45,1A13.76,13.76,0,0,0,1,44.54c0,1.34.13,3.34.25,4.64.24,2.71.7,4.23,2.08,5.26a6.06,6.06,0,0,0,3.17,1.08l3.68.12c4.11-.54,3.74.2,3.46-4.35,0-.67-.06-1.33-.06-2a18.83,18.83,0,0,1,37.65-.87q0,.44,0,.87a27.08,27.08,0,0,1-.42,5.08c-.78,4.07-1.19,3.23,3,3.52l80.39-.08c4-.5,3.65.17,3.15-4.55-.12-1.33-.18-2.67-.17-4a18.83,18.83,0,0,1,37.65-.87q0,.44,0,.87v2.85c0,4.08.6,3.73,4,3.45Z"/></g></g></g></svg>',
          name: "Coupe",
        };

      case 3:
        return {
          id: 3,
          image:
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.04 65.28"><defs><style>.cls-sportback-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;fill-rule:evenodd;}</style></defs><title>Sportback</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_1-2-2" data-name="Layer 1-2"><path class="cls-sportback-1" d="M33.21,34.86A14.71,14.71,0,1,1,18.5,49.57,14.7,14.7,0,0,1,33.21,34.86Zm131,0A14.71,14.71,0,1,1,149.5,49.57a14.7,14.7,0,0,1,14.71-14.71ZM76.35,24.31A14.89,14.89,0,0,0,77,21c.15-2.55-5.45-2.74-8.34-2.55C74.31,14.27,83.57,9.83,91,7.24c6.15-2,14.42-2.1,21-2.24,1.14,0,1.1.47,1,1.42-.47,3-1.59,9.56-2.26,14-.33,2.12-.83,2.62-3,2.64C99.18,23.08,76.24,24.72,76.35,24.31ZM116,20.92c1.18-9.64,1.32-12.44,2.23-14.78A2.2,2.2,0,0,1,119.91,5c7,.33,21.56.21,28.28,1.77,2.39.67,7.28,6.23,8.5,7.91.47.64,4,4.72,3.42,5.56a2,2,0,0,1-1.83,1c-10.46.35-29,1.45-39.46,1.58C115.9,22.85,115.73,23.56,116,20.92Zm71,35c2.08-.35,3.74-.82,4.42-1.45,2.16-2,1.5-8.27,1.47-11.48,0-.78-2-.79-2-1.6-.28-5-2.41-14-3-19.12a8,8,0,0,0-4.21-6.3C179.89,13.6,169,7.17,164.84,5.44c-2.74-1.15-2.07-1.42-5-1.85C145.25,1.5,103.62-1,89.33,3.57c-11.75,3.73-22,9.15-32.59,15.75-18.77,3.23-34.09,6-43.21,8.43C9.13,28.93,7.25,31,5.89,33.66,4.41,36.52,3.71,38.2,3,39.83c-1.62.18-2,1.63-2,3.66,0,3.17-.25,5.44.64,8.59a5.31,5.31,0,0,0,5.2,3.74l4.57.15c2.22-.42,2.9-.3,2.71-3.26-.06-1-.08-2.06-.08-3.14a19.13,19.13,0,0,1,38.25,0c0,1.48,0,2.91-.1,4.29-.33,4.71-1.79,4.22,2.73,4.46l86.58-.09c3.28-.4,4.17,0,3.71-4.47a41.93,41.93,0,0,1-.17-4.19,19.13,19.13,0,0,1,38.25,0v2.78c-.14,3.66-.94,4.36,3.71,3.56Z"/></g></g></g></svg>',
          name: "Sportback",
        };

      case 4:
        return {
          id: 4,
          image:
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.01 63.84"><defs><style>.cls-saloon-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;fill-rule:evenodd;}</style></defs><title>Saloon</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_1-2-2" data-name="Layer 1-2"><path class="cls-saloon-1" d="M32.22,34.12A14.36,14.36,0,1,1,17.87,48.49v0A14.34,14.34,0,0,1,32.22,34.12Zm42.11-10.3A16.26,16.26,0,0,0,75,20.58c.15-2.49-4.4-2.67-7.22-2.49A84.18,84.18,0,0,1,88.65,7.16,64.52,64.52,0,0,1,103,5.1l6.19-.1c1.11,0,1.26.51.93,1.39-1.1,3-1.54,9.33-2.2,13.62-.32,2.07-.81,2.56-2.93,2.57C96.61,22.62,74.23,24.23,74.33,23.82Zm103.7,31c2.91-.22,6.22-.49,8.82-.94,2-.35,3.65-.81,4.31-1.42,2.11-2,1.87-8.07,1.83-11.21,0-.76-1.94-.76-2-1.56-.28-4.82-.62-10.17-1.15-15.13-.63-6-12.85-6.18-20.33-8.55-5.33-1.69-9.71-5.3-16.55-8.32-7.53-3.33-14.1-6.38-22-6.38-12.86,0-32-1.54-44,2.27C75.53,7.22,65.08,13.81,54.2,19a312.89,312.89,0,0,0-41.12,7.37c-4.31,1-6.19,3-7.52,5.63a65,65,0,0,0-2.79,7c-1,.09-1.09-.18-1.43.44S1.19,40.91,1.11,42a25.47,25.47,0,0,0,.47,7.74c.59,2.07,1.12,3.07,2.14,3.83a5,5,0,0,0,2.81,1.07l4.89.16c2.91-.54,2.36-.91,2.18-4.62V48.49a18.67,18.67,0,0,1,37.33,0c0,1,0,2.08-.1,3.08-.43,6.25-1,5.17,4.65,5.46l78.31-.09c4.17-.57,3.93,0,3.5-5.29-.09-1-.12-2.08-.12-3.16a18.67,18.67,0,0,1,37.33,0q0,1.34-.09,2.64c-.39,4.26.82,3.87,3.61,3.69ZM139.36,21.29,140.53,7.8a29.27,29.27,0,0,0-2.81-1.11,37,37,0,0,0-9.9-1.59l-11-.13a1.85,1.85,0,0,0-1.53.72c-.88,2.29-1.09,5.41-2.24,14.82-.31,2.58-.15,1.88,2.71,1.85C122.82,22.27,131.38,21.73,139.36,21.29Zm3.09-12.63-1,12.52c2.81-.15,5.53-.28,8.05-.36a2,2,0,0,0,1.79-1A20.81,20.81,0,0,0,153,16.23c.24-.68.19-1-.41-1.81C151.86,13.34,146.59,10.59,142.45,8.66ZM155.8,34.12a14.36,14.36,0,1,1-14.36,14.36h0a14.34,14.34,0,0,1,14.35-14.35Z"/></g></g></g></svg>',
          name: "Saloon",
        };

      case 5:
        return {
          id: 5,
          image:
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.02 64.08"><defs><style>.cls-avant-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;}</style></defs><title>Avant</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_1-2-2" data-name="Layer 1-2"><g id="Layer_1-2-2-2" data-name="Layer 1-2-2"><path class="cls-avant-1" d="M32.22,34.36A14.36,14.36,0,1,1,17.87,48.73h0A14.37,14.37,0,0,1,32.22,34.36ZM74.33,24.07A14.81,14.81,0,0,0,75,20.83c.15-2.49-4.4-2.68-7.22-2.49A84.48,84.48,0,0,1,88.65,7.41,65,65,0,0,1,103,5.35l6.19-.13c.86,0,1.12.59.93,1.38-.45,2-1.54,9.34-2.2,13.62-.32,2.07-.81,2.56-2.93,2.58C96.61,22.87,74.23,24.47,74.33,24.07Zm103.7,31c2.91-.22,6.21-.5,8.82-.94,2-.35,3.65-.81,4.31-1.43,2.11-2,1.87-8.07,1.83-11.2,0-.76-1.94-.76-2-1.56-.25-4.27-.54-8.95-1-13.4l-.18-1.74-7.4-21.63c-.43-1.25-1-1.55-2.22-2a63.45,63.45,0,0,0-6.47-.06c-22.42-.1-47.21-.36-69.63.53-6.25.24-12.28.7-17.07,2.19C75.53,7.47,65.08,14.06,54.2,19.19a318.56,318.56,0,0,0-41.12,7.38c-4.31,1.07-6.19,3.07-7.52,5.64a68,68,0,0,0-2.79,7c-1,.09-1.09-.18-1.43.44a7.44,7.44,0,0,0-.23,2.57A25.71,25.71,0,0,0,1.58,50c.59,2,1.12,3,2.14,3.79a5,5,0,0,0,2.81,1.07l4.89.14c2.91-.54,2.36-.91,2.18-4.62V48.7a18.67,18.67,0,0,1,37.33,0c0,1,0,2.08-.09,3.08-.44,6.25-1,5.17,4.65,5.45l78.3-.08c4.17-.58,3.93,0,3.5-5.29-.09-1-.12-2.08-.12-3.16a18.67,18.67,0,0,1,37.33,0,23,23,0,0,1-.09,2.63c-.39,4.31.82,4,3.61,3.74Zm-34.85-36.2L141.54,9.6c-.38-2.12-.21-4.8-2.42-4.76l-22.28.38c-1.53,0-1.38-.15-1.81,1.69s-1,6.09-2,13.85c-.31,2.58-.15,1.88,2.71,1.84l24.68-.6C144,22,143.8,22.37,143.18,18.87Zm4.59.61c.44,2.42.25,2,2.87,1.94L180,20.88c3.18-1.54-2-14.18-2.17-15s-1.23-.83-2.92-.83h-27c-2.68,0-2.64,1-2.28,3Zm8,14.88a14.36,14.36,0,1,1-14.36,14.36,14.36,14.36,0,0,1,14.36-14.36Z"/></g></g></g></g></svg>',
          name: "Avant",
        };

      case 7:
        return {
          id: 7,
          image:
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.07 59.73"><defs><style>.cls-cabriolet-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;fill-rule:evenodd;}</style></defs><title>Cabriolet</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_1-2-2" data-name="Layer 1-2"><path class="cls-cabriolet-1" d="M32.45,29.77A14.48,14.48,0,1,1,18,44.25h0A14.48,14.48,0,0,1,32.45,29.77Zm123.57,0a14.48,14.48,0,1,1-14.48,14.48h0A14.47,14.47,0,0,1,156,29.77h0Zm22,20.79c2.92-.25,6.22-.47,8.83-.86a9.35,9.35,0,0,0,4.34-1.43c2.13-2,1.89-8.14,1.85-11.3,0-.77-2-.78-2-1.58-.28-4.87-.42-6.56-.95-11.56-.22-2.07-1.94-3.77-4.65-4.61-7.85-2.45-26.65-4.73-35.54-5.1L147,14l-2.77-.61c-1.84.29-1.68,0-2.08,1.9-.36,1.69-1.24,3.79-3.45,3.89-7.71.36-15.42.44-22.81.7-.78,0-1.75-.44-1.74-1.33l.06-3.36a.67.67,0,0,0-.62-.72h0L111,14.25a.82.82,0,0,0-.76.72l-.53,3c-.36,2-.7,2.55-2.69,2.64L75.12,22c-.38,0,.59-1.79.68-3.28.15-2.51-6.94-3-9.79-2.77,4-3.2,13-8.14,21-12,.92-.45,1.47-.47.87-1.42l-.6-.94c-.44-.69-1-.63-1.72-.3A334.15,334.15,0,0,0,54.82,17a315.22,315.22,0,0,0-41.47,7.44C9,25.47,6.9,25,5.56,27.56a62.27,62.27,0,0,0-2.81,7.07c-1.07.08-1.11.41-1.45,1A13.37,13.37,0,0,0,1,39.47c0,1.34.12,3.34.24,4.64.24,2.71.7,4.23,2.07,5.26a6.06,6.06,0,0,0,3.17,1l4.21.14c2.76-.39,3.26-.11,3-3.72-.07-.82-.1-1.66-.1-2.54a18.83,18.83,0,0,1,37.65,0A30.34,30.34,0,0,1,51,48.51c-.75,5,0,4.16,3.9,4.16h79.41c2.61-.53,3.53-.53,3.11-4.15a34,34,0,0,1-.21-4.28,18.83,18.83,0,0,1,37.65,0v2.1c0,3.61.14,4.47,3.14,4.22Z"/></g></g></g></svg>',
          name: "Cabriolet",
        };

      case 8:
        return {
          id: 8,
          image:
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 193.99 65.67"><defs><style>.cls-roadster-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;fill-rule:evenodd;}</style></defs><title>Roadster</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_1-2-2" data-name="Layer 1-2"><path class="cls-roadster-1" d="M157.35,32.67a16,16,0,1,1-16,16A16,16,0,0,1,157.35,32.67Zm-121.65,0a16,16,0,1,1-16,16,16,16,0,0,1,16-16Zm146.06,22.6c1.42-.16,2.85-.35,4.21-.58,2.26-.39,4.06-.9,4.8-1.59,1.71-1.59,2.11-3.89,2.14-7.32,0-2.85.68-5.25-1.73-6.24-.94-.38-1.94-.77-2-1.24-.31-5.37-.4-4.83-1-10.36-.24-2.29-2.15-4.16-5.14-5.09-13.15-4.1-24.23-5.09-38.43-5.68-2.81-.12-3.36,2.08-6.18,3.25s-3.44.93-3.42-.7c0-1.24,0-2.47.07-3.71a.76.76,0,0,0-.7-.8h0L131.58,15a.86.86,0,0,0-.85.8c-.2,1.08-.39,2.16-.59,3.23-.4,2.2-.78,3.09-3,3.36-10,1.19-23.23,2.52-33.32,2-3-.14-2.41.29-1.71-2.17a12.3,12.3,0,0,0,.51-2.41c.17-2.77-4.9-3-8-2.77A152.09,152.09,0,0,1,106.25,4.13c1-.48,1.63-.51,1-1.56l-.66-1c-.49-.77-1.08-.63-1.9-.33C92.3,5.74,81,12.43,70.72,18.05,56,19,28.94,19.76,14.09,24.74,9.39,26.31,7.48,29.61,6,32.47,4.4,35.58,3.64,36.3,2.9,38.07c-1.17.1-1.22.46-1.59,1.14S1,42.41,1,43.39c0,1.48.14,3.69.27,5.12.26,3,.77,4.68,2.29,5.81a6.76,6.76,0,0,0,3.49,1.29l4.83.16c3-.44,3.47,0,3.13-3.85-.09-1.07-.13-2.16-.13-3.26a20.79,20.79,0,0,1,41.58,0A29.51,29.51,0,0,1,55.84,55c-.79,3.49-.28,2.86,2.63,3h74.38c2.63-.52,4.26-1.45,3.89-5a38.8,38.8,0,0,1-.2-4.44,20.79,20.79,0,1,1,41.58,0v3.53C178.1,55.46,178.18,55.69,181.76,55.27Z"/></g></g></g></svg>',
          name: "Roadster",
        };

      case 9:
        return {
          id: 9,
          image:
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 193.99 65.67"><defs><style>.cls-spyder-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;fill-rule:evenodd;}</style></defs><title>Spyder</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_1-2-2" data-name="Layer 1-2"><path class="cls-spyder-1" d="M157.35,32.67a16,16,0,1,1-16,16A16,16,0,0,1,157.35,32.67Zm-121.65,0a16,16,0,1,1-16,16,16,16,0,0,1,16-16Zm146.06,22.6c1.42-.16,2.85-.35,4.21-.58,2.26-.39,4.06-.9,4.8-1.59,1.71-1.59,2.11-3.89,2.14-7.32,0-2.85.68-5.25-1.73-6.24-.94-.38-1.94-.77-2-1.24-.31-5.37-.4-4.83-1-10.36-.24-2.29-2.15-4.16-5.14-5.09-13.15-4.1-24.23-5.09-38.43-5.68-2.81-.12-3.36,2.08-6.18,3.25s-3.44.93-3.42-.7c0-1.24,0-2.47.07-3.71a.76.76,0,0,0-.7-.8h0L131.58,15a.86.86,0,0,0-.85.8c-.2,1.08-.39,2.16-.59,3.23-.4,2.2-.78,3.09-3,3.36-10,1.19-23.23,2.52-33.32,2-3-.14-2.41.29-1.71-2.17a12.3,12.3,0,0,0,.51-2.41c.17-2.77-4.9-3-8-2.77A152.09,152.09,0,0,1,106.25,4.13c1-.48,1.63-.51,1-1.56l-.66-1c-.49-.77-1.08-.63-1.9-.33C92.3,5.74,81,12.43,70.72,18.05,56,19,28.94,19.76,14.09,24.74,9.39,26.31,7.48,29.61,6,32.47,4.4,35.58,3.64,36.3,2.9,38.07c-1.17.1-1.22.46-1.59,1.14S1,42.41,1,43.39c0,1.48.14,3.69.27,5.12.26,3,.77,4.68,2.29,5.81a6.76,6.76,0,0,0,3.49,1.29l4.83.16c3-.44,3.47,0,3.13-3.85-.09-1.07-.13-2.16-.13-3.26a20.79,20.79,0,0,1,41.58,0A29.51,29.51,0,0,1,55.84,55c-.79,3.49-.28,2.86,2.63,3h74.38c2.63-.52,4.26-1.45,3.89-5a38.8,38.8,0,0,1-.2-4.44,20.79,20.79,0,1,1,41.58,0v3.53C178.1,55.46,178.18,55.69,181.76,55.27Z"/></g></g></g></svg>',
          name: "Spyder",
        };

      /* HATALI OLABİLİR */
      case 10:
        return {
          id: 10,
          image:
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.02 62.36"><defs><style>.cls-long-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;fill-rule:evenodd;}</style></defs><title>Long</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_1-2-2" data-name="Layer 1-2"><path class="cls-long-1" d="M31.51,33.36a14,14,0,1,1-14,14h0A14,14,0,0,1,31.51,33.36ZM72.65,23.3a15.54,15.54,0,0,0,.66-3.17c.14-2.43-4.3-2.61-7.06-2.43A82.21,82.21,0,0,1,86.64,7a63.05,63.05,0,0,1,14-2l6-.13c1.09,0,1.24.5.91,1.35-1.07,2.89-1.5,9.13-2.15,13.31-.31,2-.79,2.5-2.86,2.52C94.42,22.13,72.55,23.69,72.65,23.3ZM178.37,53.59c2.85-.22,6.08-.48,8.62-.92a9.59,9.59,0,0,0,4.21-1.39c2.07-1.92,1.83-7.89,1.79-11,0-.74-1.89-.75-1.94-1.52-.27-4.72-.6-9.94-1.12-14.79-.62-5.83-12.55-6-19.86-8.35-5.21-1.65-9.49-5.18-16.17-8.13-7.36-3.21-13.78-6.19-21.52-6.19C119.81,1.3,96.73-.2,85,3.52c-11.21,3.56-21.42,10-32,15A307,307,0,0,0,12.8,25.73c-4.22,1.05-6.06,3-7.35,5.51a65,65,0,0,0-2.73,6.84c-1,.09-1.07-.17-1.39.43A6.75,6.75,0,0,0,1.11,41a24.89,24.89,0,0,0,.46,7.6c.57,2,1.09,3,2.09,3.75a4.81,4.81,0,0,0,2.75,1l4.77.16C14,53,13.49,52.66,13.31,49V47.35a18.24,18.24,0,0,1,36.47,0c0,1,0,2-.09,3-.42,6.11-1,5.06,4.55,5.33l80.91-.08c4.07-.56,3.84,0,3.41-5.17-.08-1-.11-2-.11-3.09a18.24,18.24,0,0,1,36.47,0,24.52,24.52,0,0,1-.09,2.58C174.47,54.14,175.64,53.8,178.37,53.59ZM140.59,20.83l1.15-13.19A27.47,27.47,0,0,0,139,6.56,36.35,36.35,0,0,0,129.31,5l-17-.13a1.76,1.76,0,0,0-1.5.71c-.86,2.23-1.06,5.28-2.19,14.48-.31,2.51-.14,1.83,2.65,1.8C118.14,21.78,132.79,21.26,140.59,20.83Zm3-12.34-.94,12.23c2.75-.15,5.4-.28,7.87-.36a2,2,0,0,0,1.74-.94,20.21,20.21,0,0,0,1.7-3.54c.23-.67.18-1-.4-1.77-.76-1.06-5.9-3.74-9.95-5.62Zm13,24.87a14,14,0,1,1-14,14,14,14,0,0,1,14-14h0Z"/></g></g></g></svg>',
          name: "Long",
        };

      case 11:
        return {
          id: 11,
          image:
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 193.95 76.59"><defs><style>.cls-suv-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;fill-rule:evenodd;}</style></defs><title>Suv</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_1-2-2" data-name="Layer 1-2"><path class="cls-suv-1" d="M153.74,21.83c-1.39-3-3.6-8.21-5.67-12.91-1.19-2.72-1.24-3.57,1.9-3.62,6.21-.1,18,0,22.57.12a3.21,3.21,0,0,1,1.79,1c1.25,2.38,2.47,4.79,6,14.56,1,2.67.48,3.64-1.41,3.61-4.26-.08-14.93,0-21.25,0A4.14,4.14,0,0,1,153.74,21.83Zm-11.32-.3c-.39-3.78-2.35-9.54-3.56-13.37-.45-1.43-1-2.86-2.59-2.81-8.27.23-15.67.08-23.79.32a1.9,1.9,0,0,0-1.59.74c-.91,2.38-1.13,5.61-2.32,15.37-.33,2.67-.15,3,2.81,3,6.49,0,18.81,0,27.72-.2C141.77,24.51,142.75,24.79,142.42,21.53ZM69.89,25a10.29,10.29,0,0,0,.87-3.12c.15-2.58-4.57-2.77-7.5-2.58A58,58,0,0,1,75,7.27c2.65-.89,18.06-1.38,23-1.49l6.41-.13c1.16,0,1.31.52,1,1.43-.57,3.19-1.6,9.68-2.29,14.13-.33,2.14-.83,3.38-3,3.35C91.5,25,69.79,25.71,69.89,25Zm94.53,18a16.31,16.31,0,1,1-16.31,16.31h0a16.3,16.3,0,0,1,16.25-16.35h.06ZM35.22,43a16.31,16.31,0,1,1-16.3,16.32v0A16.31,16.31,0,0,1,35.19,42.92h0ZM188,61.84l.42-.1c2.51-.43,3.63-.15,4-3.4.48-3.89.31-10,.51-12.42.13-1.66-.11-3.21-1.89-3.58-1-.21-2-.42-2-.84a62.65,62.65,0,0,0-2.53-14.88,119.31,119.31,0,0,0-8.59-21.37c-2.26-4.4-1.42-4.16-7.16-4.21-20.67,0-42.46-.18-63.44.23-14,.27-27.71.36-33.55,2.22C66.19,7.72,59.09,18,53.77,22.22c-13.65,1.45-28.65,2.48-42.48,5.91-4.88,1.21-6.47,1.38-7.4,6.44-.55,3-.65,6.26-1,8H2.07c-.72,0-.89.82-.91,1.65-.1,3.08-.37,9.67.15,13.43.45,3.16,2.27,4.16,5,4.16h5c3.93,0,3.23-.36,3.5-4.12a20.44,20.44,0,0,1,40.76,0c.31,4.09-.52,4.16,3.66,4.16h80.49c4.76,0,3.83-.27,4.36-4.92a20.44,20.44,0,0,1,40.67.55c.2,2.35-.44,4.37,1.94,4.37Z"/></g></g></g></svg>',
          name: "Suv",
        };

      case 12:
        return {
          id: 12,
          image:
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.01 76.26"><defs><style>.cls-mpv-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;fill-rule:evenodd;}</style></defs><title>Mpv</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_1-2-2" data-name="Layer 1-2"><path class="cls-mpv-1" d="M32.22,46.54A14.36,14.36,0,1,1,17.87,60.91v0A14.34,14.34,0,0,1,32.22,46.54Zm105-22.61q-.33-7.29-.68-14.59a6.48,6.48,0,0,1,.31-2c.09-.42.82-.8,1.44-.81,5.68-.08,3.44-.06,9.12-.15,1.53,0,11.36,1.94,14.51,4.71,4.22,3.71,8.58,8.48,9.58,10.42.84,1.65.47,2.82-1.65,2.9-10.16.37-19.51,1.42-29.69,1.56-2.84,0-2.79.84-2.93-2ZM58.67,28.52a19.4,19.4,0,0,0,.67-3.62c.15-2.79-4.4-3-7.23-2.79C60.9,14.8,76,6.46,87.23,6.44h8.93c1.12,0,1.16.53.94,1.55-.78,3.45-1.48,11.59-2.14,16.38-.32,2.31-.81,2.86-2.93,2.88C83.69,27.31,58.56,29,58.67,28.52ZM100.15,25c1.15-10.53,1.42-15.25,2.31-17.81A1.76,1.76,0,0,1,104,6.33l25.48-.17c1.63,0,2.61,0,2.67,2.22l.45,14.89c0,1.87.29,2.82-1.84,2.89-10.21.38-17.7.71-27.89.85C100,27.05,99.84,27.83,100.15,25Zm76.17,42.13c3.26-.28,7.4-.6,10.53-1.2,2-.39,3.65-.9,4.31-1.59,2.11-2.19,1.87-9,1.83-12.53,0-.85-1.94-.86-2-1.75a87.21,87.21,0,0,0-2-16.34c-1-4.14-3.09-7-4.66-10.18-1.4-2.88-4.76-12.15-9.57-17.46A3.75,3.75,0,0,1,174,3.32c0-2.32-.32-2-2.5-2.21-2.5-.21-6.64-.06-12.24,0l-65.93.37c-3.09,0-6,.3-8.7.5C69.89,3,52.19,15.49,39.59,23.07c-7,2.47-17.85,6.55-27.29,10a9.35,9.35,0,0,0-6.14,5.65A35.37,35.37,0,0,0,4.22,45a29.53,29.53,0,0,1-1.45,4.28c-1,.1-1.09-.2-1.43.49a9,9,0,0,0-.23,2.85,31.77,31.77,0,0,0,.47,8.65c.59,2.32,1.12,3.44,2.14,4.29a4.59,4.59,0,0,0,2.81,1.2l4.53.16c3-.31,3.19.23,2.68-3.43a18.67,18.67,0,1,1,37-5.24,18.88,18.88,0,0,1-.58,7.91c-1,3.55-.52,3,2.38,3.27l82-.1c3-.29,4.31.47,3.21-3.59a18,18,0,0,1-.64-4.85,18.67,18.67,0,1,1,37,3.33C173.8,66.23,174.34,67.3,176.32,67.13ZM155.79,46.54a14.36,14.36,0,1,1-14.35,14.37v0a14.34,14.34,0,0,1,14.35-14.35Z"/></g></g></g></svg>',
          name: "Mpv",
        };

      case 13:
        return {
          id: 13,
          image:
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.02 64.08"><defs><style>.cls-estate-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;fill-rule:evenodd;}</style></defs><title>Estate</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_1-2-2" data-name="Layer 1-2"><path class="cls-estate-1" d="M32.22,34.36A14.36,14.36,0,1,1,17.87,48.73h0A14.37,14.37,0,0,1,32.22,34.36ZM74.33,24.07A14.84,14.84,0,0,0,75,20.83c.15-2.49-4.4-2.68-7.22-2.49A84.36,84.36,0,0,1,88.65,7.41,64.69,64.69,0,0,1,103,5.35l6.19-.13c.86,0,1.12.59.93,1.38-.45,2-1.54,9.34-2.2,13.62-.32,2.07-.81,2.56-2.93,2.58C96.61,22.87,74.23,24.47,74.33,24.07Zm103.7,31c2.91-.22,6.21-.5,8.82-.94,2-.35,3.65-.81,4.31-1.43,2.11-2,1.87-8.07,1.83-11.2,0-.76-1.94-.76-2-1.56-.25-4.27-.54-8.95-1-13.4l-.18-1.74-7.4-21.63c-.43-1.25-1-1.55-2.22-2a63.45,63.45,0,0,0-6.47-.06c-22.42-.1-47.21-.36-69.63.53-6.25.24-12.28.7-17.07,2.19C75.53,7.47,65.08,14.06,54.2,19.19a316.86,316.86,0,0,0-41.12,7.38c-4.31,1.07-6.19,3.07-7.52,5.64a68,68,0,0,0-2.79,7c-1,.09-1.09-.18-1.43.44a7.39,7.39,0,0,0-.23,2.57A25.46,25.46,0,0,0,1.58,50c.59,2,1.12,3,2.14,3.79a5,5,0,0,0,2.81,1.07l4.89.14c2.91-.54,2.36-.91,2.18-4.62V48.7a18.67,18.67,0,0,1,37.33,0c0,1,0,2.08-.09,3.08-.44,6.25-1,5.17,4.65,5.45l78.3-.08c4.17-.58,3.93,0,3.5-5.29-.09-1-.12-2.08-.12-3.16a18.67,18.67,0,0,1,37.33,0,25.58,25.58,0,0,1-.09,2.63c-.39,4.31.82,4,3.61,3.74Zm-34.85-36.2L141.54,9.6c-.38-2.12-.21-4.8-2.42-4.76l-22.28.38c-1.53,0-1.38-.15-1.81,1.69s-1,6.09-2,13.85c-.31,2.58-.15,1.88,2.71,1.84L140.42,22C144,22,143.8,22.37,143.18,18.87Zm4.59.61c.44,2.42.25,2,2.87,1.94L180,20.88c3.18-1.54-2-14.18-2.17-15s-1.23-.83-2.92-.83h-27c-2.68,0-2.64,1-2.28,3Zm8,14.88a14.36,14.36,0,1,1-14.36,14.36,14.36,14.36,0,0,1,14.36-14.36h0Z"/></g></g></g></svg>',
          name: "Estate",
        };

      case 14:
        return {
          id: 14,
          image:
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.03 73.91"><defs><style>.cls-pickup-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;fill-rule:evenodd;}</style></defs><title>Pickup</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_1-2-2" data-name="Layer 1-2"><path class="cls-pickup-1" d="M152.77,42.13a15.39,15.39,0,1,1-15.39,15.39h0A15.39,15.39,0,0,1,152.77,42.13Zm-122,0A15.39,15.39,0,1,1,15.43,57.57v0A15.39,15.39,0,0,1,30.82,42.13ZM60,22.62c-1,0,.52-.62.6-1.92.13-2.21-3.9-2.37-6.4-2.21L64.7,9.11A14.13,14.13,0,0,1,69,6.35a16.84,16.84,0,0,1,7.13-1.84H91c1,0,1,.4.83,1.22-.44,3-1.28,10.9-2,14.61-.33,1.82-.72,2.28-2.59,2.28ZM94.59,20.5C95.47,13.23,95.88,8,96.23,6c.15-.89.32-1.45,1.41-1.44h4.59c.37,0,.58.84.65,2.14l.72,13.14c.17,3.19-.17,2.83-1.81,2.83H96.92C94.4,22.62,94.31,22.77,94.59,20.5Zm98.08,34.76c.6-2.38.25-6.2.34-8.65.11-3-1.18-2-2.35-2.86-.24-4.28,0-11.46-.49-15.85-.54-5.13-2-5.77-7.73-5.76L115,22.29a4.36,4.36,0,0,1-2.67-.54c-1.2-.81-1.94-10.57-3.21-16.23-.68-3-.67-4.52-4-4.52H75c-4.49,0-9,2.15-12.74,5.21L45,20.18a235.37,235.37,0,0,0-36.76,6c-3.27.84-4.6,2.35-4.58,6l.07,12.15a6.75,6.75,0,0,0-1.55.3c-1,.37-1.08.68-1.18,1.67-.06.79,0,1.77,0,2.26,0,1.94.24,7.49,1.65,8.54a5.13,5.13,0,0,0,2.78.95l3.12.06c2.33-.46,2.77-1.22,3.2-3.84a19,19,0,0,1,19-16A19.31,19.31,0,0,1,50,55.44c.3,2.77,1.78,2.8,3.82,2.83l77.63.16c1.63,0,2-.92,2.08-2.45a19.3,19.3,0,0,1,38.44-.3c.24,2.58.2,2.84,2.51,2.84h6.26C183.42,58.54,192,58.13,192.67,55.26Z"/></g></g></g></svg>',
          name: "Pickup",
        };

      case 15:
        return {
          id: 15,
          image:
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.02 64.08"><defs><style>.cls-variant-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;fill-rule:evenodd;}</style></defs><title>Variant</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_1-2-2" data-name="Layer 1-2"><path class="cls-variant-1" d="M32.22,34.36A14.36,14.36,0,1,1,17.87,48.73h0A14.37,14.37,0,0,1,32.22,34.36ZM74.33,24.07A14.84,14.84,0,0,0,75,20.83c.15-2.49-4.4-2.68-7.22-2.49A84.36,84.36,0,0,1,88.65,7.41,64.69,64.69,0,0,1,103,5.35l6.19-.13c.86,0,1.12.59.93,1.38-.45,2-1.54,9.34-2.2,13.62-.32,2.07-.81,2.56-2.93,2.58C96.61,22.87,74.23,24.47,74.33,24.07Zm103.7,31c2.91-.22,6.21-.5,8.82-.94,2-.35,3.65-.81,4.31-1.43,2.11-2,1.87-8.07,1.83-11.2,0-.76-1.94-.76-2-1.56-.25-4.27-.54-8.95-1-13.4l-.18-1.74-7.4-21.63c-.43-1.25-1-1.55-2.22-2a63.45,63.45,0,0,0-6.47-.06c-22.42-.1-47.21-.36-69.63.53-6.25.24-12.28.7-17.07,2.19C75.53,7.47,65.08,14.06,54.2,19.19a316.86,316.86,0,0,0-41.12,7.38c-4.31,1.07-6.19,3.07-7.52,5.64a68,68,0,0,0-2.79,7c-1,.09-1.09-.18-1.43.44a7.39,7.39,0,0,0-.23,2.57A25.46,25.46,0,0,0,1.58,50c.59,2,1.12,3,2.14,3.79a5,5,0,0,0,2.81,1.07l4.89.14c2.91-.54,2.36-.91,2.18-4.62V48.7a18.67,18.67,0,0,1,37.33,0c0,1,0,2.08-.09,3.08-.44,6.25-1,5.17,4.65,5.45l78.3-.08c4.17-.58,3.93,0,3.5-5.29-.09-1-.12-2.08-.12-3.16a18.67,18.67,0,0,1,37.33,0,25.58,25.58,0,0,1-.09,2.63c-.39,4.31.82,4,3.61,3.74Zm-34.85-36.2L141.54,9.6c-.38-2.12-.21-4.8-2.42-4.76l-22.28.38c-1.53,0-1.38-.15-1.81,1.69s-1,6.09-2,13.85c-.31,2.58-.15,1.88,2.71,1.84L140.42,22C144,22,143.8,22.37,143.18,18.87Zm4.59.61c.44,2.42.25,2,2.87,1.94L180,20.88c3.18-1.54-2-14.18-2.17-15s-1.23-.83-2.92-.83h-27c-2.68,0-2.64,1-2.28,3Zm8,14.88a14.36,14.36,0,1,1-14.36,14.36,14.36,14.36,0,0,1,14.36-14.36h0Z"/></g></g></g></svg>',
          name: "Variant",
        };

      case 16:
        return {
          id: 16,
          image:
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.01 81.06"><defs><style>.cls-van-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;fill-rule:evenodd;}</style></defs><title>Van</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_1-2-2" data-name="Layer 1-2"><path class="cls-van-1" d="M157.1,52.44a13.81,13.81,0,1,1-13.81,13.81h0a13.8,13.8,0,0,1,13.79-13.81ZM31,52.44a13.81,13.81,0,1,1-13.8,13.82h0A13.8,13.8,0,0,1,31,52.44ZM45.72,30.49A18.08,18.08,0,0,0,46.37,27c.14-2.68-4.07-1.21-6.78-1,2.24-4.5,5.39-10.83,9.31-14.93,3.77-3.93,7.94-4.63,13.32-4.65H71c1.07,0,1.47.52,1.33,1.51-.47,3.32-1,12.14-2,16.75-.47,2.19-1.82,2.73-3.81,3.11C58.51,29.24,45.62,30.92,45.72,30.49ZM176.08,72.32c3.23-.29,7.68-.6,11-1.23,2-.37,3.5-.86,4.14-1.53,2-2.11,1.8-8.68,1.76-12,0-.83-1.94-.83-1.91-1.68,0-17.53-.28-29.3-1.21-51-.21-2.25-2.09-3.48-4.18-3.67C183.28,1,181,1,175.6,1L67.21,1.57c-7.61,0-12.91.25-19.35,4.85S36.43,21,33.58,26.61C27.3,28.81,17.71,31.67,9,34.82c-2.18.78-4.12,1.24-4.5,3.88a70.81,70.81,0,0,0-.31,8.37c0,3.59.23,6.14-.18,7.32-.3.87-2.2.2-2.65,1.13a8.32,8.32,0,0,0-.22,2.77,30.57,30.57,0,0,0,.4,8.32c.56,2.23,1.08,3.31,2.06,4.13a4.54,4.54,0,0,0,2.7,1.15l5.59.2c2.48-.2,1.66-.6,1.3-3.7A17.07,17.07,0,0,1,13,66.25a18,18,0,1,1,35.9,0,18.14,18.14,0,0,1-.62,4.69c-.86,3.2-1,3.54,2.67,3.54l86.72-.1c1.4,0,2.17,0,2.46-.43s.1-1.41-.35-3.13a18,18,0,1,1,34.79-.24C174.3,71.82,174.77,72.44,176.08,72.32Z"/></g></g></g></svg>',
          name: "Van",
        };

      /* HATALI OLABİLİR */
      case 17:
        return {
          id: 17,
          image:
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 193.99 65.67"><defs><style>.cls-coupe-convertible-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;fill-rule:evenodd;}</style></defs><title>Coupe convertible</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_1-2-2" data-name="Layer 1-2"><path class="cls-coupe-convertible-1" d="M157.35,32.67a16,16,0,1,1-16,16A16,16,0,0,1,157.35,32.67Zm-121.65,0a16,16,0,1,1-16,16,16,16,0,0,1,16-16Zm146.06,22.6c1.42-.16,2.85-.35,4.21-.58,2.26-.39,4.06-.9,4.8-1.59,1.71-1.59,2.11-3.89,2.14-7.32,0-2.85.68-5.25-1.73-6.24-.94-.38-1.94-.77-2-1.24-.31-5.37-.4-4.83-1-10.36-.24-2.29-2.15-4.16-5.14-5.09-13.15-4.1-24.23-5.09-38.43-5.68-2.81-.12-3.36,2.08-6.18,3.25s-3.44.93-3.42-.7c0-1.24,0-2.47.07-3.71a.76.76,0,0,0-.7-.8h0L131.58,15a.86.86,0,0,0-.85.8c-.2,1.08-.39,2.16-.59,3.23-.4,2.2-.78,3.09-3,3.36-10,1.19-23.23,2.52-33.32,2-3-.14-2.41.29-1.71-2.17a12.3,12.3,0,0,0,.51-2.41c.17-2.77-4.9-3-8-2.77A152.09,152.09,0,0,1,106.25,4.13c1-.48,1.63-.51,1-1.56l-.66-1c-.49-.77-1.08-.63-1.9-.33C92.3,5.74,81,12.43,70.72,18.05,56,19,28.94,19.76,14.09,24.74,9.39,26.31,7.48,29.61,6,32.47,4.4,35.58,3.64,36.3,2.9,38.07c-1.17.1-1.22.46-1.59,1.14S1,42.41,1,43.39c0,1.48.14,3.69.27,5.12.26,3,.77,4.68,2.29,5.81a6.76,6.76,0,0,0,3.49,1.29l4.83.16c3-.44,3.47,0,3.13-3.85-.09-1.07-.13-2.16-.13-3.26a20.79,20.79,0,0,1,41.58,0A29.51,29.51,0,0,1,55.84,55c-.79,3.49-.28,2.86,2.63,3h74.38c2.63-.52,4.26-1.45,3.89-5a38.8,38.8,0,0,1-.2-4.44,20.79,20.79,0,1,1,41.58,0v3.53C178.1,55.46,178.18,55.69,181.76,55.27Z"/></g></g></g></svg>',
          name: "Coupe Convertible",
        };

      case 18:
        return {
          id: 18,
          image:
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.03 70.34"><defs><style>.cls-d3-hatchback-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;fill-rule:evenodd;}</style></defs><title>D3 hatchback</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_1-2-2" data-name="Layer 1-2"><path class="cls-d3-hatchback-1" d="M35.78,37.56A15.89,15.89,0,1,1,19.89,53.45h0A15.89,15.89,0,0,1,35.76,37.56Zm126.12,0A15.89,15.89,0,1,1,146,53.47v0A15.9,15.9,0,0,1,161.9,37.56ZM82.38,26.17a15.71,15.71,0,0,0,.75-3.59c.16-2.75-5.88-3-9-2.76,6.06-4.5,16.06-9.3,24.11-12.09,6.64-2.22,15.57-2.27,22.69-2.42,1.23,0,1.19.5,1,1.53-.49,3.27-1.7,10.33-2.44,15.07-.35,2.29-.89,2.83-3.23,2.85C107,24.84,82.27,26.61,82.38,26.17Zm42.88-3.66c1.27-10.42,1.43-13.44,2.41-16a2.44,2.44,0,0,1,1.77-1.23c7.5.35,11,.23,18.23,1.91,2.58.72,7.86,6.73,9.19,8.54.51.7,4.3,5.1,3.69,6a2.19,2.19,0,0,1-2,1.07c-11.28.38-19,1.57-30.31,1.71C125.1,24.59,124.91,25.36,125.26,22.51ZM186.54,60.3c2.25-.39,4-.89,4.77-1.57,2.33-2.17,1.63-8.93,1.59-12.4,0-.84-2.15-.85-2.2-1.73-.31-5.34-2.61-15.16-3.19-20.65A8.7,8.7,0,0,0,183,17.14C178.84,14.6,167,7.66,162.58,5.78c-3-1.24-2.24-1.53-5.41-2-15.75-2.26-45.33-4.93-60.76,0-12.69,4-23.75,9.88-35.21,17-20.28,3.5-36.82,6.47-46.67,9.11-4.75,1.28-6.78,3.54-8.25,6.38-1.6,3.1-2.36,4.91-3.09,6.67C1.44,43.07,1,44.69,1,46.88c0,3.43-.27,5.88.69,9.28a5.72,5.72,0,0,0,5.62,4.05l4.94.16c2.39-.46,3.13-.33,2.93-3.53-.08-1.1-.09-2.23-.09-3.39a20.66,20.66,0,0,1,41.31-.91c0,.31,0,.61,0,.91,0,1.59,0,3.15-.11,4.64-.35,5.08-1.94,4.55,3,4.81l78.13-.09c3.55-.44,4.5,0,4-4.83q-.19-2.25-.18-4.53a20.66,20.66,0,0,1,41.31-.91c0,.31,0,.61,0,.91v3C182.39,60.37,181.52,61.16,186.54,60.3Z"/></g></g></g></svg>',
          name: "D3 Hatchback",
        };

      case 19:
        return {
          id: 19,
          image:
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.04 65.28"><defs><style>.cls-d5-hatchback-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;fill-rule:evenodd;}</style></defs><title>D5 hatchback</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_1-2-2" data-name="Layer 1-2"><path class="cls-d5-hatchback-1" d="M33.21,34.86A14.71,14.71,0,1,1,18.5,49.57,14.7,14.7,0,0,1,33.21,34.86Zm131,0A14.71,14.71,0,1,1,149.5,49.57a14.7,14.7,0,0,1,14.71-14.71ZM76.35,24.31A14.89,14.89,0,0,0,77,21c.15-2.55-5.45-2.74-8.34-2.55C74.31,14.27,83.57,9.83,91,7.24c6.15-2,14.42-2.1,21-2.24,1.14,0,1.1.47,1,1.42-.47,3-1.59,9.56-2.26,14-.33,2.12-.83,2.62-3,2.64C99.18,23.08,76.24,24.72,76.35,24.31ZM116,20.92c1.18-9.64,1.32-12.44,2.23-14.78A2.2,2.2,0,0,1,119.91,5c7,.33,21.56.21,28.28,1.77,2.39.67,7.28,6.23,8.5,7.91.47.64,4,4.72,3.42,5.56a2,2,0,0,1-1.83,1c-10.46.35-29,1.45-39.46,1.58C115.9,22.85,115.73,23.56,116,20.92Zm71,35c2.08-.35,3.74-.82,4.42-1.45,2.16-2,1.5-8.27,1.47-11.48,0-.78-2-.79-2-1.6-.28-5-2.41-14-3-19.12a8,8,0,0,0-4.21-6.3C179.89,13.6,169,7.17,164.84,5.44c-2.74-1.15-2.07-1.42-5-1.85C145.25,1.5,103.62-1,89.33,3.57c-11.75,3.73-22,9.15-32.59,15.75-18.77,3.23-34.09,6-43.21,8.43C9.13,28.93,7.25,31,5.89,33.66,4.41,36.52,3.71,38.2,3,39.83c-1.62.18-2,1.63-2,3.66,0,3.17-.25,5.44.64,8.59a5.31,5.31,0,0,0,5.2,3.74l4.57.15c2.22-.42,2.9-.3,2.71-3.26-.06-1-.08-2.06-.08-3.14a19.13,19.13,0,0,1,38.25,0c0,1.48,0,2.91-.1,4.29-.33,4.71-1.8,4.22,2.73,4.46l86.58-.09c3.28-.4,4.17,0,3.71-4.47a41.93,41.93,0,0,1-.17-4.19,19.13,19.13,0,0,1,38.25,0v2.78c-.14,3.66-.94,4.36,3.71,3.56Z"/></g></g></g></svg>',
          name: "D5 Hatchback",
        };

      case 20:
        return {
          id: 20,
          image:
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.03 70.34"><defs><style>.cls-sports-coupe-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;fill-rule:evenodd;}</style></defs><title>Sports coupe</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_1-2-2" data-name="Layer 1-2"><path class="cls-sports-coupe-1" d="M35.78,37.56A15.89,15.89,0,1,1,19.89,53.45h0A15.89,15.89,0,0,1,35.76,37.56Zm126.12,0A15.89,15.89,0,1,1,146,53.47v0A15.9,15.9,0,0,1,161.9,37.56ZM82.38,26.17a15.71,15.71,0,0,0,.75-3.59c.16-2.75-5.88-3-9-2.76,6.06-4.5,16.06-9.3,24.11-12.09,6.64-2.22,15.57-2.27,22.69-2.42,1.23,0,1.19.5,1,1.53-.49,3.27-1.7,10.33-2.44,15.07-.35,2.29-.89,2.83-3.23,2.85C107,24.84,82.27,26.61,82.38,26.17Zm42.88-3.66c1.27-10.42,1.43-13.44,2.41-16a2.44,2.44,0,0,1,1.77-1.23c7.5.35,11,.23,18.23,1.91,2.58.72,7.86,6.73,9.19,8.54.51.7,4.3,5.1,3.69,6a2.19,2.19,0,0,1-2,1.07c-11.28.38-19,1.57-30.31,1.71C125.1,24.59,124.91,25.36,125.26,22.51ZM186.54,60.3c2.25-.39,4-.89,4.77-1.57,2.33-2.17,1.63-8.93,1.59-12.4,0-.84-2.15-.85-2.2-1.73-.31-5.34-2.61-15.16-3.19-20.65A8.7,8.7,0,0,0,183,17.14C178.84,14.6,167,7.66,162.58,5.78c-3-1.24-2.24-1.53-5.41-2-15.75-2.26-45.33-4.93-60.76,0-12.69,4-23.75,9.88-35.21,17-20.28,3.5-36.82,6.47-46.67,9.11-4.75,1.28-6.78,3.54-8.25,6.38-1.6,3.1-2.36,4.91-3.09,6.67C1.44,43.07,1,44.69,1,46.88c0,3.43-.27,5.88.69,9.28a5.72,5.72,0,0,0,5.62,4.05l4.94.16c2.39-.46,3.13-.33,2.93-3.53-.08-1.1-.09-2.23-.09-3.39a20.66,20.66,0,0,1,41.31-.91c0,.31,0,.61,0,.91,0,1.59,0,3.15-.11,4.64-.35,5.08-1.94,4.55,3,4.81l78.13-.09c3.55-.44,4.5,0,4-4.83q-.19-2.25-.18-4.53a20.66,20.66,0,0,1,41.31-.91c0,.31,0,.61,0,.91v3C182.39,60.37,181.52,61.16,186.54,60.3Z"/></g></g></g></svg>',
          name: "Sports coupe",
        };

      case 21:
        return {
          id: 21,
          image:
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.02 64.08"><defs><style>.cls-sports-tourer-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;fill-rule:evenodd;}</style></defs><title>Sports tourer</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_1-2-2" data-name="Layer 1-2"><path class="cls-sports-tourer-1" d="M32.22,34.36A14.36,14.36,0,1,1,17.87,48.73h0A14.37,14.37,0,0,1,32.22,34.36ZM74.33,24.07A14.84,14.84,0,0,0,75,20.83c.15-2.49-4.4-2.68-7.22-2.49A84.36,84.36,0,0,1,88.65,7.41,64.69,64.69,0,0,1,103,5.35l6.19-.13c.86,0,1.12.59.93,1.38-.45,2-1.54,9.34-2.2,13.62-.32,2.07-.81,2.56-2.93,2.58C96.61,22.87,74.23,24.47,74.33,24.07Zm103.7,31c2.91-.22,6.21-.5,8.82-.94,2-.35,3.65-.81,4.31-1.43,2.11-2,1.87-8.07,1.83-11.2,0-.76-1.94-.76-2-1.56-.25-4.27-.54-8.95-1-13.4l-.18-1.74-7.4-21.63c-.43-1.25-1-1.55-2.22-2a63.45,63.45,0,0,0-6.47-.06c-22.42-.1-47.21-.36-69.63.53-6.25.24-12.28.7-17.07,2.19C75.53,7.47,65.08,14.06,54.2,19.19a316.86,316.86,0,0,0-41.12,7.38c-4.31,1.07-6.19,3.07-7.52,5.64a68,68,0,0,0-2.79,7c-1,.09-1.09-.18-1.43.44a7.39,7.39,0,0,0-.23,2.57A25.46,25.46,0,0,0,1.58,50c.59,2,1.12,3,2.14,3.79a5,5,0,0,0,2.81,1.07l4.89.14c2.91-.54,2.36-.91,2.18-4.62V48.7a18.67,18.67,0,0,1,37.33,0c0,1,0,2.08-.09,3.08-.44,6.25-1,5.17,4.65,5.45l78.3-.08c4.17-.58,3.93,0,3.5-5.29-.09-1-.12-2.08-.12-3.16a18.67,18.67,0,0,1,37.33,0,25.58,25.58,0,0,1-.09,2.63c-.39,4.31.82,4,3.61,3.74Zm-34.85-36.2L141.54,9.6c-.38-2.12-.21-4.8-2.42-4.76l-22.28.38c-1.53,0-1.38-.15-1.81,1.69s-1,6.09-2,13.85c-.31,2.58-.15,1.88,2.71,1.84L140.42,22C144,22,143.8,22.37,143.18,18.87Zm4.59.61c.44,2.42.25,2,2.87,1.94L180,20.88c3.18-1.54-2-14.18-2.17-15s-1.23-.83-2.92-.83h-27c-2.68,0-2.64,1-2.28,3Zm8,14.88a14.36,14.36,0,1,1-14.36,14.36,14.36,14.36,0,0,1,14.36-14.36h0Z"/></g></g></g></svg>',
          name: "Sports tourer",
        };

      case 22:
        return {
          id: 22,
          image:
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.02 64.08"><defs><style>.cls-spaceback-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:2px;fill-rule:evenodd;}</style></defs><title>Spaceback</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Layer_1-2-2" data-name="Layer 1-2"><path class="cls-spaceback-1" d="M32.22,34.36A14.36,14.36,0,1,1,17.87,48.73h0A14.37,14.37,0,0,1,32.22,34.36ZM74.33,24.07A14.84,14.84,0,0,0,75,20.83c.15-2.49-4.4-2.68-7.22-2.49A84.36,84.36,0,0,1,88.65,7.41,64.69,64.69,0,0,1,103,5.35l6.19-.13c.86,0,1.12.59.93,1.38-.45,2-1.54,9.34-2.2,13.62-.32,2.07-.81,2.56-2.93,2.58C96.61,22.87,74.23,24.47,74.33,24.07Zm103.7,31c2.91-.22,6.21-.5,8.82-.94,2-.35,3.65-.81,4.31-1.43,2.11-2,1.87-8.07,1.83-11.2,0-.76-1.94-.76-2-1.56-.25-4.27-.54-8.95-1-13.4l-.18-1.74-7.4-21.63c-.43-1.25-1-1.55-2.22-2a63.45,63.45,0,0,0-6.47-.06c-22.42-.1-47.21-.36-69.63.53-6.25.24-12.28.7-17.07,2.19C75.53,7.47,65.08,14.06,54.2,19.19a316.86,316.86,0,0,0-41.12,7.38c-4.31,1.07-6.19,3.07-7.52,5.64a68,68,0,0,0-2.79,7c-1,.09-1.09-.18-1.43.44a7.39,7.39,0,0,0-.23,2.57A25.46,25.46,0,0,0,1.58,50c.59,2,1.12,3,2.14,3.79a5,5,0,0,0,2.81,1.07l4.89.14c2.91-.54,2.36-.91,2.18-4.62V48.7a18.67,18.67,0,0,1,37.33,0c0,1,0,2.08-.09,3.08-.44,6.25-1,5.17,4.65,5.45l78.3-.08c4.17-.58,3.93,0,3.5-5.29-.09-1-.12-2.08-.12-3.16a18.67,18.67,0,0,1,37.33,0,25.58,25.58,0,0,1-.09,2.63c-.39,4.31.82,4,3.61,3.74Zm-34.85-36.2L141.54,9.6c-.38-2.12-.21-4.8-2.42-4.76l-22.28.38c-1.53,0-1.38-.15-1.81,1.69s-1,6.09-2,13.85c-.31,2.58-.15,1.88,2.71,1.84L140.42,22C144,22,143.8,22.37,143.18,18.87Zm4.59.61c.44,2.42.25,2,2.87,1.94L180,20.88c3.18-1.54-2-14.18-2.17-15s-1.23-.83-2.92-.83h-27c-2.68,0-2.64,1-2.28,3Zm8,14.88a14.36,14.36,0,1,1-14.36,14.36,14.36,14.36,0,0,1,14.36-14.36h0Z"/></g></g></g></svg>',
          name: "Spaceback",
        };

      default:
        return {
          id: null,
          image: null,
          name: "unkown",
        };
    }
  };

  return selectCar.modelId && carBodys.length > 0 ? (
    <div className="carSelectContainer-car-body">
      {carBodys.map((d) => (
        <span
          key={d}
          className={
            d === selectCar.bodyId ? "carSelectContainer-car-body-active" : ""
          }
          onClick={() => {
            handleSelect({ type: "body", id: d });

            setCarInfo({
              ...carInfo,
              type: null,
            });
          }}
        >
          <span dangerouslySetInnerHTML={{ __html: idToBody(d).image }}></span>

          <p>{idToBody(d).name}</p>
        </span>
      ))}
    </div>
  ) : (
    <Loading isLoading={selectCar.modelId && !carBodys.length > 0} />
  );
}

export default CarBodyContainer;
