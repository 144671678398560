import Settings from "../../../Settings.json";

import { useEffect, useState } from "react";
import axios from "axios";

import Loading from "../../loading";

function CarTypesContainer({ selectCar, handleSelect, carInfo, setCarInfo }) {
  const [carTypes, setCarTypes] = useState([]);

  useEffect(() => {
    if (selectCar && selectCar.modelId && selectCar.typeId === null) {
      setCarTypes([]);

      axios
        .get(
          `${Settings.API_URL}/?get=modifications&base_id=${selectCar.modelId}&body_type=${selectCar.bodyId}`
        )
        .then(function (response) {
          setCarTypes(response.data.results);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [selectCar]);

  // return selectCar.modelId && carTypes.length > 0 ? (

  return selectCar.bodyId && carTypes.length > 0 ? (
    <div className="carSelectContainer-types">
      {carTypes.map((d) => (
        <label
          onClick={() => {
            handleSelect({ type: "type", id: d.model });
            setCarInfo({ ...carInfo, type: d.model });
          }}
          key={d.model}
          className={
            d.model === selectCar.typeId
              ? "carSelectContainer-types-active"
              : ""
          }
        >
          {d.model}
        </label>
      ))}
    </div>
  ) : (
    <Loading isLoading={selectCar.bodyId && !carTypes.length > 0} />
  );
}

export default CarTypesContainer;
