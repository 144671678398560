import Settings from "../../Settings.json";

import homeBannerImage from "../../assets/home-banner.jpeg";
import homeBannerImageTwo from "../../assets/home-banner-two.jpeg";

import ReactTypingEffect from "react-typing-effect";

function homeBanner() {
  return (
    <div
      className="homeBanner"
      id="home"
      style={{
        backgroundImage: `url(${
          window.innerWidth < 992 ? homeBannerImageTwo : homeBannerImage
        })`,
      }}
    >
      <div className="homeBanner-content">
        <h2 className="homeBanner-content-title">{Settings.homeBannerText}</h2>

        <ReactTypingEffect
          speed="50"
          eraseDelay="1500"
          eraseSpeed="50"
          typingDelay="1000"
          text={Settings.homeBannerTexts}
          cursorRenderer={(cursor) => (
            <h2 className="home-banner-h2">{cursor}</h2>
          )}
          displayTextRenderer={(text, i) => {
            return <h2 className="home-banner-h2">{text}</h2>;
          }}
        />

        <span
          onClick={() =>
            document.getElementById("supported-vehicles").scrollIntoView()
          }
          className="homeBanner-content-button"
        >
          {Settings.homeBannerButtonText}
        </span>
      </div>
    </div>
  );
}

export default homeBanner;
