import vwLogo from "../../assets/vw-logo.png";
import seatLogo from "../../assets/seat-logo.png";
import audiLogo from "../../assets/audi-logo.png";
import skodaLogo from "../../assets/skoda-logo.png";
import lmaborghiniLogo from "../../assets/lamborghini-logo.png";
import bentleyLogo from "../../assets/bentley-logo.png";
import ust from "../../assets/ust.png";

function vehicleBrandsBanner() {
  return (
    <div className="vehicleBrandsBanner">
      <div className="vehicleBrandsBanner-header">
        <span
          onClick={() =>
            document.getElementById("supported-vehicles").scrollIntoView()
          }
        >
          <img src={ust} alt="" />
        </span>
      </div>

      <div className="vehicleBrandsBanner-content">
        <div>
          <img src={audiLogo} alt="audiLogo" />
        </div>

        <div>
          <img src={vwLogo} alt="vwLogo" />
        </div>

        <div>
          <img src={skodaLogo} alt="skodaLogo" />
        </div>

        <div>
          <img src={seatLogo} alt="seatLogo" />
        </div>

        <div>
          <img src={lmaborghiniLogo} alt="lmaborghiniLogo" />
        </div>

        <div>
          <img src={bentleyLogo} alt="bentleyLogo" />
        </div>
      </div>
    </div>
  );
}

export default vehicleBrandsBanner;
