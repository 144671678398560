import Settings from "../../Settings.json";

function detailBanner() {
  return (
    <section className="detailBanner-container" id="detail-banner">
      <h2 dangerouslySetInnerHTML={{ __html: Settings.detailBannerTitle }}></h2>
      <div className="detailBanner">
        {Settings.detailBanner.length > 0 &&
          Settings.detailBanner.map((d) => (
            <section key={d.id}>
              <img src={require(`../../assets/icons/${d.img}`)} alt={d.img} />

              <h3>{d.title}</h3>

              <p dangerouslySetInnerHTML={{ __html: d.parag }}></p>
            </section>
          ))}
      </div>
    </section>
  );
}

export default detailBanner;
